<template>
  <b-container class="page-container">
    <bread-crumbs currentpage="Bevestiging van je reservering" />
    <b-col class="d-none d-md-block">
      <div class="h-100">
        <p class="text-right">
          <a href="/">Terug naar de catalogus</a>
        </p>
      </div>
    </b-col>
    <h1>Bevestiging van de reservering</h1>
    <hr />
    <h3>Overzicht van je reservering</h3>
    <b-row>
      <b-col md="4">
        <b-card class="h100">
          <div>
            <strong>Afhaallocatie</strong>
            <p>{{ reservation.WarehouseID }}</p>
          </div>
          <div>
            <strong>Afhaaldatum</strong>
            <p>
              {{ reservation.DateTimeBusinessStart | moment('DD/MM/YYYY') }}
            </p>
          </div>
          <div>
            <strong>Terugbrengdatum</strong>
            <p>
              {{ reservation.DateTimeExpectedEnd | moment('DD/MM/YYYY') }}
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-table :items="reservation.ReservationItems" :fields="fields">
          <template v-slot:cell(DbTotalExcVAT)="data">
            <div class="form-group text-right">
              &euro;
              {{
                (Math.round(data.item.TotalIncVAT * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
        </b-table>
        <div class="prices ">
          <b-row>
            <b-col cols="9" class="text-right cart-total-price">
              <p>
                <strong>BTW:</strong>
              </p>
            </b-col>
            <b-col cols="3" class="text-right price-values pr-1">
              <p>
                &euro;
                {{
                  reservation.TotalVAT.toFixed(2)
                    .toString()
                    .replace('.', ',')
                }}
              </p>
            </b-col>
            <b-col cols="9" class="text-right cart-total-price">
              <p>
                <strong>Totaalbedrag:</strong>
              </p>
            </b-col>

            <b-col cols="3" class="text-right price-values pr-1">
              <p>
                &euro;
                {{
                  reservation.TotalIncVAT.toFixed(2)
                    .toString()
                    .replace('.', ',')
                }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12" class="mt-5">
        <b-card>
          <a
            class="btn btn-primary float-right"
            :href="`/betaling/${reservation.ReservationOrderID}`"
            >Betaal nu
            <font-awesome-icon class="ml-1" fas :icon="['fas', 'credit-card']"
          /></a>
          <p class="pt-2">
            Deze reservatie kun je binnen de 14 dagen wijzigen.<br />
            Toch zeker van je zaak? Betaal dan nu.
          </p>
        </b-card>
      </b-col>

      <b-col cols="12" class="mt-3">
        <hr />
        <h3>Nog producten reserveren?</h3>

        <router-link
          class="btn btn-primary mt-2"
          :to="{ name: 'index' }"
          variant="primary"
          >Terug naar catalogus</router-link
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: 'ItemDescription',
          label: 'Item'
        },
        {
          key: 'cartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'Amount',
          label: 'Aantal',
          class: 'text-center'
        },
        {
          key: 'DbTotalExcVAT',
          label: 'Totaal',
          class: 'text-right pr-0'
        }
      ]
    }
  },
  computed: {
    reservation: function() {
      let reservationData = this.$store.getters.reservation.data

      reservationData.ReservationItems = reservationData.ReservationItems.filter(
        function(obj) {
          return obj.Description !== 'Administratiekosten'
        }
      )
      return reservationData
    }
  }
}
</script>

<style scoped lang="scss">
.prices {
  padding-right: 10px;
}
h1 {
  font-size: 50px;
}
h3 {
  font-size: 35px;
}
.card,
p,
.table {
  font-size: 18px;
}
.card {
}
a {
  text-decoration: none;
}
.price-values {
  p {
    padding-bottom: 3px !important;
  }
}
</style>
